import { Col, Row, Tag } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import StatusDropdown from "../../../../../../components/StatusDropdown";
import { CheckmarkIcon } from "../../../../../../assets/icons";
import { Button, Label, Loading } from "../../../../../../components/ui-components";

import { STATUS_TASK_STATUSES, TaskStatusesKey } from "../../../../../../consts/status.constants";
import { RootState } from "../../../../../../store";

import styles from "./StatusTaskContent.module.scss";

type StatusTaskContentProps = {
  data: {
    [key: string]: any; // todo
  };
};

export default function StatusTaskContent({ data }: StatusTaskContentProps) {
  const { t: tGlobal } = useTranslation("dashboard", { keyPrefix: "global" });

  const userRole = useSelector((state: RootState) => state.user.user_role);
  const { hasAdminPermissions } = userRole || {};
  const isPending = true; // todo

  const handleUpdateStatus = (status: TaskStatusesKey) => {
    console.log(status);
  };

  return (
    <div className={styles.wrapper}>
      {data ? (
        <>
          <Row justify='space-between' wrap={false} align='middle' gutter={16}>
            <Col>
              <div className={styles.type}>{data.type.split("_").join(" ")}</div>
              <h1 className={styles.title}>{data.name} Cash and cash equivalents</h1>
              <Tag className={styles.headerTag}>Existence / Occurrence</Tag>
            </Col>
            <Col>
              {hasAdminPermissions ? (
                <StatusDropdown
                  statuses={[...STATUS_TASK_STATUSES]}
                  value={data.status}
                  onChange={handleUpdateStatus}
                />
              ) : (
                <Button
                  primary
                  style={{ padding: "5px 8px", gap: 10 }}
                  color={isPending ? "green" : "lightGreen"}
                  disabled={true} // todo
                  handleClick={() => handleUpdateStatus(isPending ? "in_progress" : "pending")}
                >
                  <CheckmarkIcon />
                  {isPending ? tGlobal("done") : tGlobal("mark_as_done")}
                </Button>
              )}
            </Col>
          </Row>
          <div className={styles.descriptionWrapper}>
            <Label>Description</Label>
            <div>{data.description}</div>
          </div>
        </>
      ) : (
        <Loading />
      )}
      {/*<RichTextEditor />*/}
    </div>
  );
}
