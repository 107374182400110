import React, { useState, useRef, useEffect, ReactNode } from "react";
import styles from "./ResizableBlocks.module.scss";

interface ResizableBlocksProps {
  id?: string;
  pageName?: string;
  leftContent?: ReactNode;
  rightContent?: ReactNode;
}

const ResizableBlocks: React.FC<ResizableBlocksProps> = ({
  id,
  pageName,
  leftContent,
  rightContent,
}) => {
  const [leftWidth, setLeftWidth] = useState<number>(50);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const isResizing = useRef<boolean>(false);
  const localStorageKey = `${pageName}-${id}-blockWidth`;

  useEffect(() => {
    const savedWidth = localStorage.getItem(localStorageKey);
    if (savedWidth) {
      setLeftWidth(parseFloat(savedWidth));
    }

    return () => {
      setLeftWidth(50);
    };
  }, [localStorageKey]);

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    isResizing.current = true;
    e.preventDefault();
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (!isResizing.current || !containerRef.current) return;

    const containerRect = containerRef.current.getBoundingClientRect();
    const newLeftWidth = ((e.clientX - containerRect.left) / containerRect.width) * 100;

    if (newLeftWidth >= 25 && newLeftWidth <= 75) {
      setLeftWidth(newLeftWidth);
      localStorage.setItem(localStorageKey, newLeftWidth.toString());
    }
  };

  const handleMouseUp = () => {
    isResizing.current = false;
  };

  useEffect(() => {
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, []);

  return (
    <div ref={containerRef} className={styles.container}>
      <div className={styles.leftBlock} style={{ width: `${leftWidth}%` }}>
        {leftContent}
      </div>
      <div className={styles.resizer} onMouseDown={handleMouseDown} />
      <div className={styles.rightBlock} style={{ width: `${100 - leftWidth}%` }}>
        {rightContent}
      </div>
    </div>
  );
};

export default ResizableBlocks;
