import { Space, Tabs } from "antd";
import { useTranslation } from "react-i18next";

import { Button } from "../../../../../../components/ui-components";
import { Document } from "../../../../../../types/document";
import { StatusStep } from "../../../../../../types/status";
import StatusAccounts from "./StatusAccounts";
import StatusContacts from "./StatusContacts";
import StatusFindings from "./StatusFindings";
import RiskResponse from "./StatusRiskResponse";
import SimilarTasks from "./StatusSimilarTasks";
import StatusDocuments from "./StatusDocuments";

import { ReactComponent as RestoreIcon } from "../../../../../../assets/icons/restore.svg";
import { ReactComponent as ArrowDownIcon } from "../../../../../../assets/icons/arrow-down.svg";

import styles from "./StatusTaskDetails.module.scss";

type Props = {
  documents: Array<Document>;
  data: StatusStep;
};

export default function StatusTaskDetails({ documents, data }: Props) {
  const { t } = useTranslation("dashboard", { keyPrefix: "status" });
  const { t: tGlobal } = useTranslation("dashboard", { keyPrefix: "global" });

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.titleWrapper}>
          <h3 className={styles.title}>{t("details")}</h3>
          <Space>
            <Button primary icon={<RestoreIcon />} color='gray' />
          </Space>
        </div>
        <Tabs
          moreIcon={
            <div className={styles.tabsMore}>
              {tGlobal("more")} <ArrowDownIcon />
            </div>
          }
          rootClassName={styles.tabs}
          defaultActiveKey='1'
          items={[
            {
              label: t("risk_response"),
              key: "risk_response",
              children: <RiskResponse data={data} />,
            },
            {
              label: tGlobal("documents"),
              key: "documents",
              children: <StatusDocuments documents={documents} />,
            },
            { label: t("findings"), key: "findings", children: <StatusFindings /> },
            { label: tGlobal("accounts"), key: "accounts", children: <StatusAccounts /> },
            { label: t("similar_tasks"), key: "similar_tasks", children: <SimilarTasks /> },
            { label: tGlobal("contacts"), key: "contacts", children: <StatusContacts /> },
          ]}
        />
      </div>
    </div>
  );
}
