import { Space } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as SearchIcon } from "../../../../../../../assets/icons/search.svg";
import { ReactComponent as PencilIcon } from "../../../../../../../assets/icons/pencil-filled.svg";
import { ReactComponent as PlusIcon } from "../../../../../../../assets/icons/plus.svg";
import { Button } from "../../../../../../../components/ui-components";
import { StatusStep } from "../../../../../../../types/status";
import StatusTableActions from "../StatusTableActions";
import RiskResponseViewDrawer from "./RiskResponseViewDrawer";
import SubtasksTable from "./SubtasksTable";
import RisksTable from "./RisksTable";

import styles from "./RiskResponse.module.scss";

type Props = {
  data: StatusStep;
};

export default function RiskResponse({ data }: Props) {
  const { t } = useTranslation("dashboard", { keyPrefix: "status" });
  const { t: tGlobal } = useTranslation("dashboard", { keyPrefix: "global" });

  const [editMode, setEditMode] = useState(false);
  const [isOpenRiskView, setIsOpenRiskView] = useState(false);
  const [type, setType] = useState<"risks" | "subtasks">("risks");

  const handleEdit = () => setEditMode(true);

  const handleCloseEdit = () => setEditMode(false);

  const handleOpenRiskView = () => setIsOpenRiskView(true);

  const handleCloseRiskView = () => setIsOpenRiskView(false);

  const handleSave = () => {
    handleCloseEdit();
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h3 className={styles.title}>{t("risk_response")}</h3>
        <div className={styles.headerActions}>
          {editMode ? (
            <>
              <Button primary color='lightRed' onClick={handleCloseEdit}>
                {tGlobal("cancel")}
              </Button>
              <Button primary color='green' onClick={handleSave}>
                {tGlobal("save")}
              </Button>
            </>
          ) : (
            <>
              <Button color='gray' icon={<SearchIcon />} />
              <Button color='lightBlue' icon={<PencilIcon />} onClick={handleEdit} />
              <Button small primary color='blue' onClick={handleOpenRiskView}>
                <PlusIcon /> {tGlobal("add_new")}
              </Button>
            </>
          )}
        </div>
      </div>
      <div className={styles.subheader}>
        <Space>
          <Button
            small
            primary
            color={type === "risks" ? "dark" : "gray"}
            onClick={() => setType("risks")}
          >
            {t("risks")}
          </Button>
          <Button
            small
            primary
            color={type === "subtasks" ? "dark" : "gray"}
            onClick={() => setType("subtasks")}
          >
            {t("subtasks")}
          </Button>
        </Space>
        {!editMode && <StatusTableActions />}
      </div>
      {type === "risks" ? (
        <RisksTable editMode={editMode} />
      ) : (
        <SubtasksTable list={data?.subtasks} />
      )}
      <RiskResponseViewDrawer
        documents={data?.attachedDocuments}
        open={isOpenRiskView}
        onClose={handleCloseRiskView}
      />
    </div>
  );
}
