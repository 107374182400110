import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import orderBy from "lodash/orderBy";

import { useFetch } from "../../../../hooks";
import {
  superadminDeleteUser,
  superadminGetUsers,
  superadminToggleSuperadmin,
} from "../../../../api/superadminApi";
import { SORT_TITLES } from "./Users.constants";

import Tabs from "../../../../components/ui-components/Tabs/Tabs";
import SearchInput from "../../../../components/ui-components/SearchInput/SearchInput";
import TableHeader from "../../../components/TableHeader";
import { Loading } from "../../../../components/ui-components";
import UserRow from "./components/UserRow";
import MakeSuperadminPopup from "./components/MakeSuperadminPopup";
import ConfirmDeletePopup from "../../../../components/ConfirmDeletePopup";
import PlatformAdminTitleBlock from "../../components/PlatformAdminTitleBlock";
import EditUser from "./components/EditUser";

import "./temporary-styles.css";
import styles from "./Users.module.scss";

const tabs = ["all", "auditor", "super-admin"];

const Users = () => {
  const { t } = useTranslation("admin", { keyPrefix: "users" });

  const [tab, setTab] = useState("all");
  const [search, setSearch] = useState("");
  const [toggleSuperadminPopup, setToggleSuperadminPopup] = useState(null);
  const [deleteUserPopup, setDeleteUserPopup] = useState(null);
  const [editUser, setEditUser] = useState({});
  const [filters, setFilters] = useState();
  const { isLoading, data, setData } = useFetch(superadminGetUsers);

  const filteredUsers = useMemo(() => {
    const searchLower = search.toLowerCase();
    let filtered = data?.filter((user) => {
      const userMatch =
        user &&
        (user.firstName.toLowerCase().includes(searchLower) ||
          user.lastName.toLowerCase().includes(searchLower) ||
          user.email.toLowerCase().includes(searchLower));

      switch (tab) {
        case "auditor":
          return user.teamType === "audit_firm" && userMatch;
        case "super-admin":
          return user.role === "super-admin" && userMatch;
        default:
          return userMatch;
      }
    });

    if (filters) {
      filtered = orderBy(filtered, [filters.order], [filters.orderBy]);
    }

    return filtered;
  }, [data, tab, search, filters]);

  const editCurrentUser = (userId, body) => {
    setData(
      data.map((user) => {
        if (user.id === userId) {
          return {
            ...user,
            ...body,
          };
        }
        return user;
      })
    );
  };

  const confirmToggleSuperAdmin = async (initBody, callback) => {
    const { id, role } = initBody || toggleSuperadminPopup;
    const body = { role: role === "super-admin" ? "user" : "super-admin" };
    await superadminToggleSuperadmin(id, body);
    editCurrentUser(id, body);
    setToggleSuperadminPopup(null);
    callback?.();
  };

  const confirmDeleteUser = async () => {
    await superadminDeleteUser(deleteUserPopup);
    setData(data.filter((user) => user.email !== deleteUserPopup));
    setDeleteUserPopup(null);
  };

  const closeEditUser = (updatedFields) => {
    if (updatedFields) {
      setData(
        data.map((user) => (user.id === updatedFields.id ? { ...user, ...updatedFields } : user))
      );
    }
    setEditUser({});
  };

  const closeToggleSuperAdmin = () => setToggleSuperadminPopup(null);
  const closeDeleteUserPopup = () => setDeleteUserPopup(null);
  const userLength = filteredUsers?.length || 0;

  return (
    <>
      <EditUser editUser={editUser} close={closeEditUser} />
      <MakeSuperadminPopup
        isOpen={!!toggleSuperadminPopup}
        onClose={closeToggleSuperAdmin}
        onSubmit={confirmToggleSuperAdmin}
        data={toggleSuperadminPopup}
      />
      <ConfirmDeletePopup
        isOpen={!!deleteUserPopup}
        onClose={closeDeleteUserPopup}
        title={t("delete_user")}
        description={t("delete_description")}
        placeholder={t("delete_placeholder")}
        matchValue={deleteUserPopup}
        onSubmit={confirmDeleteUser}
      />
      <PlatformAdminTitleBlock />
      <div className={styles.actions}>
        <Tabs value={tab} onClick={setTab} options={tabs} t={t} />
        <SearchInput value={search} onChange={setSearch} placeholder={t("search_users")} />
      </div>
      <TableHeader
        titles={SORT_TITLES}
        wrapperStyles={{ gridTemplateColumns: "minmax(180px, 1fr) 200px 200px 120px 125px 32px" }}
        filters={filters}
        onChange={setFilters}
      />
      {!isLoading ? (
        userLength > 0 ? (
          filteredUsers.map((item, index) => (
            <UserRow
              {...item}
              zIndex={userLength - index}
              key={index}
              setToggleSuperadminPopup={setToggleSuperadminPopup}
              confirmToggleSuperAdmin={confirmToggleSuperAdmin}
              setDeleteUserPopup={setDeleteUserPopup}
              setEditUser={setEditUser}
            />
          ))
        ) : (
          <span />
        )
      ) : (
        <Loading className={styles.loader} />
      )}
    </>
  );
};

export default Users;
