import { Slider, SliderSingleProps } from "antd";
import clsx from "clsx";
import { ReactComponent as ChevronUp } from "../../assets/icons/chevron-up.svg";
import { ReactComponent as DoubleChevronDown } from "../../assets/icons/double-chevron-down.svg";
import { ReactComponent as DoubleChevronUp } from "../../assets/icons/double-chevron-up.svg";

import styles from "./CustomSlider.module.scss";

type CustomSliderProps = {
  viewMode?: boolean;
} & SliderSingleProps;

export default function CustomSlider({ viewMode, ...props }: CustomSliderProps) {
  const lowValue = !props?.value || props?.value <= 30;
  const mediumValue = props?.value && props.value >= 30 && props.value <= 70;
  const highValue = props?.value && props.value >= 70;
  return (
    <div className={styles.wrapper}>
      {viewMode ? (
        <>
          <div
            className={clsx(styles.viewValue, {
              [styles.viewValueGreen]: lowValue,
              [styles.viewValueOrange]: mediumValue,
              [styles.viewValueRed]: highValue,
            })}
          >
            {lowValue && (
              <>
                <DoubleChevronDown />
                <span>Low</span>
              </>
            )}
            {mediumValue && (
              <>
                <ChevronUp />
                <span>Medium</span>
              </>
            )}
            {highValue && (
              <>
                <DoubleChevronUp />
                <span>High</span>
              </>
            )}
            {props?.value || 0}%
          </div>
        </>
      ) : (
        <>
          <div className={styles.topLegend}>
            <div className={styles.topLegendItem}>
              <DoubleChevronDown />
              Low
            </div>
            <div className={styles.topLegendItem}>
              <ChevronUp />
              Medium
            </div>
            <div className={styles.topLegendItem}>
              <DoubleChevronUp />
              High
            </div>
          </div>
          <Slider className={styles.slider} tooltip={{ formatter: null }} {...props} />
          <div className={styles.bottomLegend}>
            {Array.from({ length: 11 }).map((_, index) => (
              <div key={index} className={styles.bottomLegendItem}>
                {index * 10}%
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}
