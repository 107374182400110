import { Col, Divider, Drawer, DrawerProps, Input, Row } from "antd";
import clsx from "clsx";
import { convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Attachments } from "../../../../../../../../Admin/pages/components";
import PageTextEditor from "../../../../../../../../Admin/pages/components/PageTextEditor/PageTextEditor";
import StatusDropdown, {
  StatusDropdownStatus,
} from "../../../../../../../../components/StatusDropdown";
import { ThreeDotsIcon } from "../../../../../../../../assets/icons";

import { ReactComponent as ArrowLeftIcon } from "../../../../../../../../assets/icons/arrow-left.svg";
import { ReactComponent as ArrowsIcon } from "../../../../../../../../assets/icons/arrows-horizontal.svg";
import { ReactComponent as InfoIcon } from "../../../../../../../../assets/icons/info.svg";
import { ReactComponent as PlusIcon } from "../../../../../../../../assets/icons/plus-rounded-filled.svg";
import { Button, Label } from "../../../../../../../../components/ui-components";

import styles from "./CreateFinding.module.scss";

interface FormInputs {
  type: "quantitative" | "qualitative" | "improvement";
  description: string;
  recommendedMeasures: string;
  preventiveMeasures: string;
  managementResponse: string;
  correctionEntries: { debit: string; credit: string; description: string; amount: number }[];
  impactRiskAssessment: string;
}

type Props = {
  onClose: () => void;
} & DrawerProps;

const statuses: Array<StatusDropdownStatus<string>> = [
  { key: "not_started", color: "default" },
  { key: "in_progress", color: "blue" },
  { key: "pending", color: "yellow" },
  { key: "blocked", color: "red" },
  { key: "done", color: "green" },
];

type EditorStates =
  | "description"
  | "managementResponse"
  | "preventiveMeasures"
  | "impactRiskAssessment"
  | "recommendedMeasures";

const FINDING_TYPES: Array<FormInputs["type"]> = ["quantitative", "qualitative", "improvement"];

export default function CreateFinding({ onClose, ...props }: Props) {
  const { t } = useTranslation("dashboard", { keyPrefix: "status" });
  const { t: tGlobal } = useTranslation("dashboard", { keyPrefix: "global" });
  const [editorStates, setEditorStates] = useState<Record<EditorStates, EditorState>>({
    description: EditorState.createEmpty(),
    managementResponse: EditorState.createEmpty(),
    preventiveMeasures: EditorState.createEmpty(),
    impactRiskAssessment: EditorState.createEmpty(),
    recommendedMeasures: EditorState.createEmpty(),
  });

  const { control, handleSubmit, setValue, watch } = useForm<FormInputs>({
    defaultValues: {
      type: "quantitative",
      correctionEntries: [{ debit: "", credit: "", description: "", amount: 0 }],
    },
  });

  const { fields, append } = useFieldArray({
    control,
    name: "correctionEntries",
  });

  const onEditorStateChange = (state: EditorState, name: EditorStates) => {
    setEditorStates((prev) => ({ ...prev, [name]: state }));
  };

  const onSubmit = (data: FormInputs) => {
    const htmlDescription = draftToHtml(convertToRaw(editorStates.description.getCurrentContent()));
    console.log(data, htmlDescription);
  };

  const type = watch("type");

  return (
    <Drawer
      width='750px'
      classNames={{
        wrapper: styles.wrapper,
        body: styles.drawerBody,
        header: styles.drawerHeader,
      }}
      {...props}
    >
      <div className={styles.header}>
        <Button
          color='gray'
          className={styles.backButton}
          icon={<ArrowLeftIcon width={24} height={24} />}
          onClick={onClose}
        />
        <div>{t("create_findings")}</div>
      </div>
      <form className={styles.body} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.inner}>
          <div className={styles.titleWrapper}>
            <Row align='middle' justify='space-between'>
              <Col>
                <h3 className={styles.title}>123</h3>
              </Col>
              <Col>
                <StatusDropdown
                  statuses={statuses}
                  value='not_started'
                  onChange={(v) => console.log(v)}
                />
              </Col>
            </Row>
          </div>
          <div>
            <Label gutterBottom>{t("finding_type")}</Label>
            <Row gutter={16} justify='space-between'>
              {FINDING_TYPES.map((i) => (
                <Col span={8} key={i}>
                  <Button
                    color='gray'
                    className={clsx(styles.typeButton, watch("type") === i && styles.active)}
                    onClick={() => setValue("type", i)}
                  >
                    {t(i)}
                  </Button>
                </Col>
              ))}
            </Row>
          </div>
          <div>
            <PageTextEditor
              value={watch("description")}
              editorState={editorStates.description}
              onEditorStateChange={onEditorStateChange}
              name='description'
              label={tGlobal("description")}
            />
          </div>
          <div>
            <Attachments />
          </div>
        </div>
        {type === "quantitative" && (
          <>
            <Divider />
            <div className={styles.entriesWrapper}>
              <h4 className={styles.entriesTitle}>{t("correction_entries")}</h4>
              <div className={styles.entriesList}>
                {fields.map((field, index) => (
                  <Row key={field.id} wrap={false} gutter={16} align='bottom'>
                    <Col span={8}>
                      <Row gutter={2} align='middle' wrap={false}>
                        <Col flex={1}>
                          <Label gutterBottom>{tGlobal("debit")}</Label>
                        </Col>
                        <Col span={3}></Col>
                        <Col flex={1}>
                          <Label gutterBottom>{tGlobal("credit")}</Label>
                        </Col>
                      </Row>
                      <Row gutter={2} align='middle' wrap={false}>
                        <Col flex={1}>
                          <Controller
                            name={`correctionEntries.${index}.debit`}
                            control={control}
                            render={({ field }) => <Input {...field} />}
                          />
                        </Col>
                        <Col span={3}>
                          <ArrowsIcon className={styles.arrowsIcon} />
                        </Col>
                        <Col flex={1}>
                          <div>
                            <Controller
                              name={`correctionEntries.${index}.credit`}
                              control={control}
                              render={({ field }) => <Input {...field} />}
                            />
                          </div>
                        </Col>
                      </Row>
                    </Col>

                    <Col span={10}>
                      <Label gutterBottom>{tGlobal("descriptions")}</Label>
                      <Controller
                        name={`correctionEntries.${index}.description`}
                        control={control}
                        render={({ field }) => <Input {...field} />}
                      />
                    </Col>

                    <Col flex={1}>
                      <Label gutterBottom>{t("finding_amount_in")}</Label>
                      <Row wrap={false} align='middle' gutter={8}>
                        <Col flex={1}>
                          <Controller
                            name={`correctionEntries.${index}.amount`}
                            control={control}
                            render={({ field }) => <Input type='number' {...field} />}
                          />
                        </Col>
                        <Col>
                          <Button
                            className={styles.dotsButton}
                            color='gray'
                            icon={<ThreeDotsIcon />}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col></Col>
                  </Row>
                ))}
              </div>

              <Button
                small
                primary
                color='lightBlue'
                onClick={() => append({ debit: "", credit: "", description: "", amount: 0 })}
              >
                <PlusIcon />
                {t("finding_add_correction_entry")}
              </Button>
            </div>
            <Divider />
          </>
        )}
        <div className={styles.inner}>
          {(type === "quantitative" || type === "qualitative") && (
            <>
              {type === "qualitative" && (
                <>
                  <Divider className={styles.divider} />
                  <div>
                    <PageTextEditor
                      value={watch("recommendedMeasures")}
                      editorState={editorStates.recommendedMeasures}
                      onEditorStateChange={onEditorStateChange}
                      name='recommendedMeasures'
                      label={t("finding_recommended_measures")}
                    />
                  </div>
                </>
              )}
              <div>
                <PageTextEditor
                  value={watch("managementResponse")}
                  editorState={editorStates.managementResponse}
                  onEditorStateChange={onEditorStateChange}
                  name='managementResponse'
                  label={t("finding_management_response")}
                  subtitle={
                    <div className={styles.alert}>
                      <InfoIcon />
                      {t("finding_management_response_description")}
                    </div>
                  }
                />
              </div>
              <div>
                <PageTextEditor
                  value={watch("preventiveMeasures")}
                  editorState={editorStates.preventiveMeasures}
                  onEditorStateChange={onEditorStateChange}
                  name='preventiveMeasures'
                  label={t("finding_preventive_measures")}
                  subtitle={
                    <div className={styles.alert}>
                      <InfoIcon />
                      {t("finding_preventive_measures_description")}
                    </div>
                  }
                />
              </div>
            </>
          )}
          <div>
            <PageTextEditor
              value={watch("impactRiskAssessment")}
              editorState={editorStates.impactRiskAssessment}
              onEditorStateChange={onEditorStateChange}
              name='impactRiskAssessment'
              label={t("finding_impact_risk_assessment")}
            />
          </div>
        </div>
      </form>
    </Drawer>
  );
}
