import { Collapse, CollapseProps } from "antd";
import clsx from "clsx";
import { ReactComponent as ChevronDownIcon } from "../../../../../../../assets/icons/chevron-down.svg";
import styles from "./StyledCollapse.module.scss";

type Props = {
  className?: string;
  editMode?: boolean;
  items: CollapseProps["items"];
};

export default function StyledCollapse({ className, items }: Props) {
  return (
    <Collapse
      accordion
      bordered={false}
      items={items}
      className={clsx(styles.collapse, className)}
      expandIcon={() => <ChevronDownIcon />}
      expandIconPosition='end'
      defaultActiveKey={["1"]}
    />
  );
}
