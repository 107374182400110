import { Drawer, DrawerProps } from "antd";
import React, { ReactNode } from "react";

import { ReactComponent as ArrowLeft } from "../../assets/icons/arrow-left.svg";
import styles from "./CustomDrawer.module.scss";

type CustomDrawerProps = {
  title?: string;
  onBack?: () => void;
  rightHeaderContent?: ReactNode;
  children: ReactNode;
} & DrawerProps;

const CustomDrawer: React.FC<CustomDrawerProps> = ({
  title,
  onBack,
  rightHeaderContent,
  children,
  ...props
}) => {
  return (
    <Drawer
      width={750}
      {...props}
      closeIcon={<ArrowLeft width={24} height={24} />}
      className={styles.wrapper}
      title={
        <div className={styles.header}>
          <div className={styles.title}>{title}</div>
          <div className={styles.headerContent}>{rightHeaderContent}</div>
        </div>
      }
      styles={{
        header: { display: "flex", alignItems: "center", padding: "0 24px" },
      }}
    >
      {children}
    </Drawer>
  );
};

export default CustomDrawer;
