import clsx from "clsx";
import { Tag } from "../../../../../../../../components/ui-components";
import { ReactComponent as ChartLineIcon } from "../../../../../../../../assets/icons/chart-line.svg";
import { ReactComponent as StarIcon } from "../../../../../../../../assets/icons/star-filled.svg";
import { ReactComponent as ImprovementIcon } from "../../../../../../../../assets/icons/vertical-dot-circle.svg";
import styles from "./FindingsItem.module.scss";

type FindingsItemProps = {
  title: string;
  description?: string;
  type: "improvement" | "quantitative" | "qualitative";
  status?: string;
};

const iconsByType = {
  improvement: <ImprovementIcon />,
  quantitative: <ChartLineIcon />,
  qualitative: <StarIcon />,
};

export default function FindingsItem({ description, status, title, type }: FindingsItemProps) {
  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <div className={styles.contentInner}>
          <div className={clsx(styles.type, styles[type])}>
            {iconsByType[type]}
            <span>{type}</span>
          </div>
          <div className={styles.title}>{title}</div>
          {description && <div className={styles.description}>{description}</div>}
        </div>
      </div>
      <div className={styles.typeWrapper}>
        <Tag className={styles.tag} color='orange'>
          {status}
        </Tag>
      </div>
    </div>
  );
}
