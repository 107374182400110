import clsx from "clsx";
import { ReactComponent as ChevronUp } from "../../../../../../../../assets/icons/chevron-up.svg";
import { ReactComponent as Ellipse } from "../../../../../../../../assets/icons/ellipse.svg";
import { ReactComponent as DoubleChevronDown } from "../../../../../../../../assets/icons/double-chevron-down.svg";
import { ReactComponent as DoubleChevronUp } from "../../../../../../../../assets/icons/double-chevron-up.svg";
import { ReactComponent as ArrowSouthWest } from "../../../../../../../../assets/icons/arrow-south-west.svg";
import { Tag } from "../../../../../../../../components/ui-components";
import styles from "./RiskItem.module.scss";

type RiskItemProps = {
  title: string;
  description?: string;
  type?: string;
  status?: string;
};

export default function RiskItem({ description, status, title, type }: RiskItemProps) {
  const getStatus = () => {
    switch (status) {
      case "high":
        return (
          <div className={clsx(styles.status, styles.danger)}>
            <DoubleChevronUp />
            High
          </div>
        );
      case "medium":
        return (
          <div className={clsx(styles.status, styles.warning)}>
            <ChevronUp />
            Medium
          </div>
        );
      case "low":
        return (
          <div className={clsx(styles.status, styles.success)}>
            <DoubleChevronDown />
            Low
          </div>
        );
      case "inquiry":
        return (
          <div className={clsx(styles.status, styles.gap2, styles.info)}>
            <Ellipse />
            Inquiry
          </div>
        );
      case "general":
        return (
          <div className={clsx(styles.status, styles.gap2, styles.general)}>
            <Ellipse />
            General
          </div>
        );
      default:
        return (
          <div className={clsx(styles.status, styles.success)}>
            <DoubleChevronDown />
            Low
          </div>
        );
    }
  };
  return (
    <div className={styles.root}>
      <div className={styles.status}>{getStatus()}</div>
      <div className={styles.content}>
        <div className={styles.title}>{title}</div>
        {description && (
          <div className={styles.description}>
            <ArrowSouthWest width='1.25em' height='1.25em' />
            {description}
          </div>
        )}
      </div>
      <div className={styles.typeWrapper}>
        <Tag nowrap color='yellow'>
          {type}
        </Tag>
      </div>
    </div>
  );
}
