import { Space } from "antd";
import { useTranslation } from "react-i18next";

import { ReactComponent as AdjustmentsHorizontalIcon } from "../../../../../../../assets/icons/adjustments-horizontal.svg";
import { ReactComponent as ArrowsSortIcon } from "../../../../../../../assets/icons/arrows-sort.svg";
import { ReactComponent as FiltersIcon } from "../../../../../../../assets/icons/filters.svg";
import { Button } from "../../../../../../../components/ui-components";

type Props = {
  onFilter?: () => void;
  onSort?: () => void;
  onView?: () => void;
};

export default function StatusTableActions({ onFilter, onSort, onView }: Props) {
  const { t } = useTranslation("dashboard", { keyPrefix: "global" });

  return (
    <Space>
      <Button secondary color='gray' small onClick={onFilter}>
        <FiltersIcon /> {t("filters")}
      </Button>
      <Button secondary color='gray' small onClick={onSort}>
        <ArrowsSortIcon /> {t("sort")}
      </Button>
      <Button secondary color='gray' small onClick={onView}>
        <AdjustmentsHorizontalIcon /> {t("view")}
      </Button>
    </Space>
  );
}
