import { useState } from "react";
import DetailsContentHeader from "../DetailsContentHeader";

import styles from "./Accounts.module.scss";

export default function Accounts() {
  const [isOpenCreate, setIsOpenCreate] = useState<boolean>(false);

  return (
    <div className={styles.wrapper}>
      <DetailsContentHeader title='Accounts' onAdd={() => setIsOpenCreate(true)} />
    </div>
  );
}
