import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../../../../../../../components/ui-components";

import { ReactComponent as PlusIcon } from "../../../../../../../assets/icons/plus.svg";
import { ReactComponent as SearchIcon } from "../../../../../../../assets/icons/search.svg";
import StatusTableActions from "../StatusTableActions";

import styles from "./DetailsContentHeader.module.scss";

type Props = {
  extraFilters?: ReactNode;
  title: string;
  onAdd?: () => void;
};

export default function DetailsContentHeader({ extraFilters, title, onAdd }: Props) {
  const { t: tGlobal } = useTranslation("dashboard", { keyPrefix: "global" });

  return (
    <div>
      <div className={styles.header}>
        <h3 className={styles.title}>{title}</h3>
        <div className={styles.headerActions}>
          <Button color='gray' icon={<SearchIcon />} />
          {onAdd && (
            <Button small primary color='blue' onClick={onAdd}>
              <PlusIcon />
              {tGlobal("add_new")}
            </Button>
          )}
        </div>
      </div>
      <div className={styles.filters}>
        <div>{extraFilters}</div>
        <StatusTableActions />
      </div>
    </div>
  );
}
