import { Drawer, DrawerProps } from "antd";
import { useParams } from "react-router-dom";

import { ReactComponent as ArrowLeftIcon } from "../../../../../assets/icons/arrow-left.svg";

import { Document } from "../../../../../types/document";
import { StatusStep } from "../../../../../types/status";
import HeaderAdmin from "../../../../../Admin/components/HeaderAdmin";
import ResizableBlocks from "../../../../../components/ResizableBlocks";
import { Button } from "../../../../../components/ui-components";
import StatusTaskDetails from "./StatusTaskDetails";
import StatusTaskContent from "./StatusTaskContent";

import styles from "./StatusTask.module.scss";

type Params = {
  id: string;
};

type StatusTaskProps = {
  data: StatusStep;
  documents: Array<Document>;
  editMode: boolean;
  onClose?: () => void;
} & DrawerProps;

export default function StatusTask({
  data,
  documents,
  editMode,
  onClose,
  ...props
}: StatusTaskProps) {
  const { id: auditId } = useParams<Params>();

  return (
    <Drawer {...props} closable={false} width='100%' rootClassName={styles.root}>
      <HeaderAdmin
        backButton={
          <div>
            <Button small secondary color='gray' onClick={onClose}>
              <ArrowLeftIcon width={24} height={24} /> Back
            </Button>
          </div>
        }
      />
      <ResizableBlocks
        id={auditId}
        pageName={window.location.href.replace(window.location.origin, "")}
        leftContent={<StatusTaskContent data={data} />}
        rightContent={<StatusTaskDetails documents={documents} data={data} />}
      />
    </Drawer>
  );
}
